import Vue from "vue";
import Router from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/Login")
    },
    {
      path: "/reset",
      name: "reset",
      component: () => import("@/views/auth/Reset")
    },
    {
      path: "/manual",
      name: "manual",
      component: () => import("@/views/auth/Manual")
    },
    {
      path: "/reset/confirm",
      name: "reset-confirm",
      component: () => import("@/views/auth/ResetConfirm.vue")
    },
    {
      path: "/reset/success",
      name: "reset-success",
      component: () => import("@/views/auth/ResetSuccess.vue")
    },
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: "/admin",
      name: "admin",
      component: DashboardLayout,
      redirect: "admin/dashboard",
      children: [
        {
          path: "dashboard",
          name: "admin-dashboard",
          component: () => import("@/views/SuperAdmin/Dashboard")
        },
        {
          path: "likuidasi",
          name: "admin-likuidasi",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "dailyreport",
          name: "admin-dailyreport",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "monthlyplan",
          name: "admin-monthlyplan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "target",
          name: "admin-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "datausers",
          name: "admin-user",
          component: () => import("@/views/SuperAdmin/DataUser")
        },
        {
          path: "datadistributor",
          name: "admin-distributor",
          component: () => import("@/views/SuperAdmin/DataDistributor")
        },
        {
          path: "dataretailer",
          name: "admin-retailer",
          component: () => import("@/views/SuperAdmin/DataRetailer")
        },
        {
          path: "dataproduk",
          name: "admin-produk",
          component: () => import("@/views/SuperAdmin/DataProduk")
        },
        {
          path: "datapetani",
          name: "admin-petani",
          component: () => import("@/views/SuperAdmin/DataPetani")
        },
        {
          path: "dataregion",
          name: "admin-region",
          component: () => import("@/views/SuperAdmin/DataRegion")
        },
        {
          path: "dataprovinsi",
          name: "admin-province",
          component: () => import("@/views/SuperAdmin/DataProvinsi")
        },
        {
          path: "datakabupaten",
          name: "admin-kabupaten",
          component: () => import("@/views/SuperAdmin/DataKabupaten")
        },
        {
          path: "datagroup",
          name: "admin-groups",
          component: () => import("@/views/SuperAdmin/DataGrup")
        },
        {
          path: "dataperiode",
          name: "admin-periods",
          component: () => import("@/views/SuperAdmin/DataPeriode")
        },
        {
          path: "datacountry",
          name: "admin-country",
          component: () => import("@/views/SuperAdmin/DataCountry")
        },
        {
          path: "datatanaman",
          name: "admin-tanaman",
          component: () => import("@/views/SuperAdmin/DataTanaman")
        },
        {
          path: "matprotypes",
          name: "admin-mptypes",
          component: () => import("@/views/SuperAdmin/MatproTypes")
        },
        {
          path: "matpro",
          name: "admin-matpro",
          component: () => import("@/views/SuperAdmin/Matpro")
        },
        {
          path: "datakategori",
          name: "admin-kategori",
          component: () => import("@/views/SuperAdmin/KategoriPestisida")
        },
        {
          path: "tss-approval",
          name: "admin-tssapv",
          component: () => import("@/views/SuperAdmin/TSSApproval")
        },
        {
          path: "settings",
          name: "admin-settings",
          component: () => import("@/views/MT/Settings")
        }
        // {
        //   path: "icons",
        //   name: "Icons",
        //   component: Icons
        // },
        // {
        //   path: "maps",
        //   name: "Maps",
        //   component: Maps
        // },
        // {
        //   path: "notifications",
        //   name: "Notifications",
        //   component: Notifications
        // },
        // {
        //   path: "user",
        //   name: "User Profile",
        //   component: UserProfile
        // },
        // {
        //   path: "table",
        //   name: "Table List",
        //   component: TableList
        // },
        // {
        //   path: "typography",
        //   name: "Typography",
        //   component: Typography
        // }
      ]
    },
    {
      path: "/cm",
      name: "cm",
      component: DashboardLayout,
      redirect: "cm/dashboard",
      children: [
        {
          path: "dashboard",
          name: "cm-dashboard",
          component: () => import("@/views/HO/Dashboard")
        },
        {
          path: "laporanpenjualan",
          name: "cm-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "laporanharian",
          name: "cm-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "rencanabulanan",
          name: "cm-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "target",
          name: "cm-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "lapkegiatansp",
          name: "cm-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "datapetani",
          name: "cm-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "cm-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "settings",
          name: "cm-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/advisor",
      name: "adv",
      component: DashboardLayout,
      redirect: "advisor/dashboard",
      children: [
        {
          path: "dashboard",
          name: "adv-dashboard",
          component: () => import("@/views/HO/Dashboard")
        },
        {
          path: "laporanpenjualan",
          name: "adv-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "laporanharian",
          name: "adv-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "rencanabulanan",
          name: "adv-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "target",
          name: "adv-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "lapkegiatansp",
          name: "adv-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "datapetani",
          name: "adv-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "adv-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "settings",
          name: "adv-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/nsm",
      name: "nsm",
      component: DashboardLayout,
      redirect: "nsm/dashboard",
      children: [
        {
          path: "dashboard",
          name: "nsm-dashboard",
          component: () => import("@/views/HO/Dashboard")
        },
        {
          path: "lapkegiatansp",
          name: "nsm-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "lappenjualan",
          name: "nsm-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "laporanharian",
          name: "nsm-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "settarget",
          name: "nsm-set-target",
          component: () => import("@/views/NSM/DataSetTarget")
        },
        {
          path: "target",
          name: "nsm-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "rencanabulanan",
          name: "nsm-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "datapetani",
          name: "nsm-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "nsm-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "settings",
          name: "nsm-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/nmm",
      name: "nmm",
      component: DashboardLayout,
      redirect: "nmm/dashboard",
      children: [
        {
          path: "dashboard",
          name: "nmm-dashboard",
          component: () => import("@/views/HO/Dashboard")
        },
        {
          path: "lapkegiatansp",
          name: "nmm-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "datapetani",
          name: "nmm-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "nmm-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "laporanpenjualan",
          name: "nmm-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "target",
          name: "nmm-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "laporanharian",
          name: "nmm-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "rencanabulanan",
          name: "nmm-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "settings",
          name: "nmm-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/mt",
      name: "mt",
      component: DashboardLayout,
      redirect: "mt/dashboard",
      children: [
        {
          path: "dashboard",
          name: "mt-dashboard",
          component: () => import("@/views/MT/Dashboard")
        },
        {
          path: "lapkegiatansp",
          name: "mt-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "datapetani",
          name: "mt-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "mt-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "laporanpenjualan",
          name: "mt-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "laporanharian",
          name: "mt-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "rencanabulanan",
          name: "mt-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "target",
          name: "mt-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "req-matpro",
          name: "mt-matpro",
          component: () => import("@/views/MT/MP_ReqMatpro")
        },
        {
          path: "approved-matpro",
          name: "mt-appmatpro",
          component: () => import("@/views/MT/MP_ApprovedMatpro")
        },
        {
          path: "processed-matpro",
          name: "mt-prcmatpro",
          component: () => import("@/views/MT/MP_ProcessedMatpro")
        },
        {
          path: "req-sample",
          name: "mt-sample",
          component: () => import("@/views/MT/SP_ReqSample")
        },
        {
          path: "approved-sample",
          name: "mt-appsample",
          component: () => import("@/views/MT/SP_ApprovedSample")
        },
        {
          path: "processed-sample",
          name: "mt-prcsample",
          component: () => import("@/views/MT/SP_ProcessedSample")
        },
        {
          path: "settings",
          name: "mt-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/sa",
      name: "sa",
      component: DashboardLayout,
      redirect: "sa/dashboard",
      children: [
        {
          path: "dashboard",
          name: "sa-dashboard",
          component: () => import("@/views/HO/Dashboard")
        },
        {
          path: "laporanpenjualan",
          name: "sa-sales-report",
          component: () => import("@/views/HO/LapPenjualan")
        },
        {
          path: "dailyreport",
          name: "sa-daily-report",
          component: () => import("@/views/HO/DailyReport")
        },
        {
          path: "monthlyplan",
          name: "sa-monthly-plan",
          component: () => import("@/views/HO/MonthlyPlan")
        },
        {
          path: "target",
          name: "sa-target",
          component: () => import("@/views/HO/DataTarget")
        },
        {
          path: "laporankegiatan",
          name: "sa-laporankegiatan",
          component: () => import("@/views/HO/SPReport")
        },
        {
          path: "datapetani",
          name: "sa-petani",
          component: () => import("@/views/HO/DataPetani")
        },
        {
          path: "datasp",
          name: "sa-sp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "settings",
          name: "sa-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/rm",
      name: "rm",
      component: DashboardLayout,
      redirect: "/rm/dashboard",
      children: [
        {
          path: "dashboard",
          name: "rm-dashboard",
          component: () => import("@/views/RM/Dashboard")
        },
        {
          path: "amreport",
          name: "rm-amreport",
          component: () => import("@/views/RM/AMReports/Index.vue")
        },
        {
          path: "spreport",
          name: "rm-spreport",
          component: () => import("@/views/AM/SPReports/Index.vue")
        },
        {
          path: "salesreport",
          name: "rm-salesreport",
          component: () => import("@/views/RM/LapPenjualan")
        },
        {
          path: "settarget",
          name: "rm-settarget",
          component: () => import("@/views/RM/SetTarget")
        },
        {
          path: "dailyreport",
          name: "rm-dailyreport",
          component: () => import("@/views/RM/DailyReport")
        },
        {
          path: "tssreport",
          name: "rm-tssreport",
          component: () => import("@/views/AM/TSSReports/Index")
        },
        {
          path: "datapetani",
          name: "rm-datapetani",
          component: () => import("@/views/RM/RMPetani")
        },
        {
          path: "datasp",
          name: "rm-datasp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "settings",
          name: "rm-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/am",
      name: "am",
      component: DashboardLayout,
      redirect: "/am/dashboard",
      children: [
        {
          path: "dashboard",
          name: "am-dashboard",
          component: () => import("@/views/AM/Dashboard")
        },
        {
          path: "salesreport",
          name: "am-salesreport",
          component: () => import("@/views/AM/LapPenjualan")
        },
        {
          path: "settarget",
          name: "am-settarget",
          component: () => import("@/views/AM/SetTarget")
        },
        {
          path: "dailyreport",
          name: "am-dailyreport",
          component: () => import("@/views/AM/DailyReport")
        },
        {
          path: "tssreport",
          name: "am-tssreport",
          component: () => import("@/views/AM/TSSReports/Index")
        },
        {
          path: "spreport",
          name: "am-spreport",
          component: () => import("@/views/AM/SPReports/Index")
        },
        {
          path: "datasp",
          name: "am-datasp",
          component: () => import("@/views/HO/DataSP")
        },
        {
          path: "datapetani",
          name: "am-datapetani",
          component: () => import("@/views/AM/AMPetani")
        },
        {
          path: "settings",
          name: "am-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/tss",
      name: "tss",
      component: DashboardLayout,
      redirect: "tss/dashboard",
      children: [
        {
          path: "dashboard",
          name: "tss-dashboard",
          component: () => import("@/views/TSS/Dashboard.vue")
        },
        {
          path: "spreport",
          name: "tss-spreport",
          component: () => import("@/views/TSS/SPReports/Index")
        },
        {
          path: "salesreport",
          name: "tss-salesreport",
          component: () => import("@/views/TSS/LapPenjualan.vue")
        },
        {
          path: "dailyreport",
          name: "tss-dailyreport",
          component: () => import("@/views/TSS/DailyReport.vue")
        },
        {
          path: "settarget",
          name: "tss-settarget",
          component: () => import("@/views/TSS/SetTarget.vue")
        },
        {
          path: "monthlyplan",
          name: "tss-monthlyplan",
          component: () => import("@/views/TSS/MonthlyPlan.vue")
        },
        {
          path: "datasp",
          name: "tss-datasp",
          component: () => import("@/views/TSS/DataSP.vue")
        },
        {
          path: "datapetani",
          name: "tss-datapetani",
          component: () => import("@/views/TSS/TSSPetani.vue")
        },
        {
          path: "modul-matpro",
          name: "tss-invs",
          component: () => import("@/views/TSS/Matpro/Index")
        },
        {
          path: "modul-sampel",
          name: "tss-invp",
          component: () => import("@/views/TSS/Sample/Index")
        },
        {
          path: "spapproval",
          name: "tss-spapproval",
          component: () => import("@/views/TSS/SPApproval.vue")
        },
        {
          path: "settings",
          name: "tss-settings",
          component: () => import("@/views/MT/Settings")
        }
      ]
    },
    {
      path: "/sp",
      name: "laporan-sp",
      component: DashboardLayout,
      redirect: "sa/lapkegiatansp",
      children: [
        {
          path: "laporankegiatan",
          name: "lapkegiatanSP",
          component: () => import("@/views/HO/SPReport")
        }
      ]
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("@/views/404.vue")
    }
  ]
});

export default router;
