<template>
  <div class="sidebar sidebar-fixed" :data="backgroundColor">
    <div class="sidebar-wrapper text-left">
      <div class="logo row">
        <div class="ml-2 align-self-center">
          <img
            v-if="pp !== 'null'"
            :src="url + pp"
            :alt="pp"
            class="mx-1"
            style="max-width: 55px; border-radius: 16px;"
          />
          <img v-else src="@/assets/img/upllogo.png" alt="UPL_LOGO" />
        </div>
        <div class="simple-text text-dark logo-normal col mt-1">
          <div class="role-text ml-1 box-limit">{{ nama }}</div>
          <div class="email-text ml-1 box-limit">{{ email }}</div>
          <div class="row ml-1 badge badge-gray">{{ role }}</div>
        </div>
      </div>
      <ul class="nav mb-4 pb-3">
        <slot>
          <sidebar-link> </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "side-bar",
  components: {
    SidebarLink
  },
  props: {
    backgroundColor: {
      type: String,
      default: "green"
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // publicPath: process.env.VUE_APP_BASE_URL,
      activeLinkIndex: 0,
      nama: JwtService.getName(),
      email: JwtService.getEmail(),
      role: this.convertRoles(JwtService.getRole()),
      pp: JwtService.getPP(),
      url: JwtService.getURL()
    };
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    // ConvertToRole(data) {
    //   let Value;
    //   switch (data) {
    //     case "SUPER_ADMIN":
    //       Value = "SUPER ADMIN";
    //       break;
    //     case "CM":
    //       Value = "Country Head of Indonesia";
    //       break;
    //     case "NMM":
    //       Value = "Marketing Head of Indonesia";
    //       break;
    //     case "NSM":
    //       Value = "National Sales Manager";
    //       break;
    //     case "MT":
    //       Value = "Marketing Team";
    //       break;
    //     case "SA":
    //       Value = "Sales Admin";
    //       break;
    //     case "RM":
    //       Value = "Regional Manager";
    //       break;
    //     case "AM":
    //       Value = "Areal Manager";
    //       break;
    //     case "TSS":
    //       Value = "Territory Sales Supervisor";
    //       break;
    //     case "SP":
    //       Value = "Sales Promotor";
    //       break;
    //     default:
    //       Value = "Role Invalid";
    //       break;
    //   }
    //   return Value;
    // },
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    }
  }
};
</script>
<style>
.sidebar-wrapper {
  overflow-x: hidden !important;
}

.role-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* font-size: .5vw; */
  line-height: 24px;
  color: #757575;
}

.email-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}

.box-limit {
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
</style>
