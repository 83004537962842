<script>
export default {
  props: {
    cs: String
  }
};
</script>

<template>
  <b-tr>
    <b-td :colspan="cs" class="text-center">
      <b-spinner small></b-spinner>
      <span class="spinner-label">Loading...</span>
    </b-td>
  </b-tr>
</template>
