<template>
  <div class="wrapper" v-if="this.isAuthenticated">
    <!-- <div class="wrapper"> -->
    <!-- <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}"> -->
    <side-bar :background-color="backgroundColor">
      <!-- <mobile-menu slot="content"></mobile-menu> -->
    </side-bar>
    <!-- <sidebar-share :background-color.sync="backgroundColor"></sidebar-share> -->

    <div class="main-panel" :data="backgroundColor">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./DashboardContent.vue";
import SideBar from "@/components/SidebarPlugin/SideBar.vue";
import JwtService from "@/core/services/jwt.service";
import { CLEAR } from "@/core/services/store/auth.module";
import store from "@/core/services/store";
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    // ContentFooter,
    // MobileMenu,
    SideBar
    // SidebarLink,
    // SidebarShare
  },
  data() {
    return {
      backgroundColor: "light",
      role: JwtService.getRole(),
      token: JwtService.getToken()
    };
  },
  mounted() {
    // console.log(this.isAuthenticated);
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    } else {
      if (this.role == "SUPER_ADMIN") {
        if (
          this.$route.path.split("/")[1] !=
          this.role.toLowerCase().split("_")[1]
        ) {
          this.$router.replace("/admin");
        }
      } else {
        if (this.$route.path.split("/")[1] != this.role.toLowerCase()) {
          this.$router.replace(`/${this.role.toLowerCase()}`);
        }
      }
    }
    // this.refreshToken();
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    refreshToken() {
      return new Promise((resolve, reject) => {
        const refresh = this.$apollo.query({
          query: gql`
            query($token: String!) {
              refreshToken(token: $token) {
                token
              }
            }
          `,
          variables: {
            token: this.token
          }
        });
        if (resolve) {
          resolve(refresh);
        } else {
          reject(refresh);
        }
      })
        .then(res => {
          JwtService.saveToken(res.data.refreshToken.token);
        })
        .catch(() => {
          Promise.all([store.dispatch(CLEAR)]);
          this.$router.push({ name: "login" });
        });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
  // beforeEach(next) {
  //   Promise.all([store.dispatch(VERIFY_AUTH, this.idUser)]).then(next);
  //   // Scroll page to top on every route change
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 100);
  // }
};
</script>

<style>
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
  /* border: 3px solid orange; */
}
</style>
