import gql from "graphql-tag";

export const COUNTRIES = gql`
  query allRegion {
    listRegion(listRegionInput: { order: { sortBy: ASC, orderBy: REGION } }) {
      regions {
        region
      }
    }
  }
`;
export const Q_LIST_FARMER = gql`
  query listPetani(
    $page: Int
    $limit: Int
    $keyword: String
    $tss: [String!]
    $sp: [String!]
    $country_id: [Int!]
    $region_id: [Int!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listPetani(
      listPetaniInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          tss_id: $tss
          sp_id: $sp
          country_id: $country_id
          region_id: $region_id
          prov_id: $province_id
          kab_id: $kabupaten_id
        }
      }
    ) {
      totalPage
      total
      page
      petanis {
        id
        name
        sp {
          id
          name
        }
        tss {
          id
          name
        }
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        prov {
          id
          province
        }
        kab {
          id
          kabupaten
        }
        kec {
          id
          kecamatan
        }
        kel {
          id
          kelurahan
        }
        kab_id
        luas
        alamat
        hp
        ucrops {
          id
          crop
        }
        ocrops {
          id
          crop
        }
      }
    }
  }
`;
export const Q_LIST_RETAILER = gql`
  query listRetailer(
    $keyword: String
    $type: retailerType
    $country_id: [ID!]
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listRetailer(
      listRetailerInput: {
        page: 1
        limit: 999
        search: {
          keyword: $keyword
          type: $type
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
      }
    ) {
      total
      retailers {
        id
        name
        owner
        retailer_type
      }
    }
  }
`;
export const Q_LIST_DISTRIBUTOR = gql`
  query listDistributor(
    $keyword: String
    $country_id: [ID!]
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listDistributor(
      listDistributorInput: {
        page: 1
        limit: 999
        search: {
          keyword: $keyword
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
      }
    ) {
      total
      distributors {
        id
        name
        owner
        email
      }
    }
  }
`;
export const Q_LIST_RETAILER_DETAIL = gql`
  query listRetailer(
    $keyword: String
    $type: retailerType
    $country_id: [ID!]
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listRetailer(
      listRetailerInput: {
        page: 1
        limit: 999
        search: {
          keyword: $keyword
          type: $type
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
      }
    ) {
      total
      retailers {
        id
        name
        retailer_type
        country_id
        region_id
        province_id
        kabupaten_id
      }
    }
  }
`;
export const Q_LIST_DISTRIBUTOR_DETAIL = gql`
  query listDistributor(
    $keyword: String
    $country_id: [ID!]
    $region_id: [ID!]
    $province_id: [String!]
    $kabupaten_id: [String!]
  ) {
    listDistributor(
      listDistributorInput: {
        page: 1
        limit: 999
        search: {
          keyword: $keyword
          country_id: $country_id
          region_id: $region_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: []
          kelurahan_id: []
        }
      }
    ) {
      total
      distributors {
        id
        name
        owner
        email
      }
    }
  }
`;
export const Q_DETAIL_USER = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      detail {
        country_id
        region_id
        province_id
        kabupaten_id
      }
    }
  }
`;
export const detailUser = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      ugdn
      email
      phone
      name
      isActive
      profile
      detail {
        id
        group_id
        groups {
          id
          group
        }
        country_id
        countrys {
          id
          country
        }
        region_id
        regions {
          id
          region
        }
        province_id
        province {
          id
          province
        }
        kabupaten_id
        kabupaten {
          id
          kabupaten
        }
      }
    }
  }
`;
export const detailAuthorization = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      name
      role
      approvePropos {
        id
        canApprove
        canDeliver
        canProcess
      }
    }
  }
`;
export const detailWilayahQuery = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      detail {
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        province {
          id
          province
        }
        kabupaten {
          id
          kabupaten
        }
      }
    }
  }
`;
export const Q_LIST_USER = gql`
  query listUser(
    $page: Int
    $limit: Int
    $keyword: String
    $role: roleType
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $tss_id: [String!]
  ) {
    listUser(
      listUserInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          role: $role
          country_id: $country_id
          region_id: $region_id
          province_id: $prov_id
          kabupaten_id: $kab_id
          kecamatan_id: $kec_id
          kelurahan_id: $kel_id
          tss_id: $tss_id
        }
      }
    ) {
      page
      total
      totalPage
      users {
        id
        ugdn
        email
        phone
        name
        role
        isActive
        detail {
          countrys {
            id
            country
          }
          regions {
            id
            region
          }
          province {
            id
            province
          }
          kabupaten {
            id
            kabupaten
          }
        }
      }
    }
  }
`;
export const Q_LIST_USERBSC = gql`
  query listUserBsc(
    $page: Int
    $limit: Int
    $keyword: String
    $role: roleType
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $tss_id: [String!]
  ) {
    listUser(
      listUserInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          role: $role
          country_id: $country_id
          region_id: $region_id
          province_id: $prov_id
          kabupaten_id: $kab_id
          kecamatan_id: $kec_id
          kelurahan_id: $kel_id
          tss_id: $tss_id
        }
      }
    ) {
      page
      total
      totalPage
      users {
        id
        phone
        role
        name
      }
    }
  }
`;
export const Q_LIST_USERSEARCH = gql`
  query listUser(
    $keyword: String
    $role: roleType
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $tss_id: [String!]
    $orderBy: UserOrderType!
    $sortBy: SortByType!
  ) {
    listUser(
      listUserInput: {
        page: 1
        limit: 99
        search: {
          keyword: $keyword
          role: $role
          isActive: true
          country_id: $country_id
          region_id: $region_id
          province_id: $prov_id
          kabupaten_id: $kab_id
          kecamatan_id: $kec_id
          kelurahan_id: $kel_id
          tss_id: $tss_id
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      users {
        id
        name
        role
      }
    }
  }
`;
export const Q_LIST_PRODUK = gql`
  query listProduk(
    $page: Int
    $limit: Int
    $keyword: String
    $isMatpro: Boolean
    $isSample: Boolean
  ) {
    listProduk(
      listProdukInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        filter: { isMatpro: $isMatpro, isSample: $isSample }
      }
    ) {
      produks {
        id
        brand
        price
      }
    }
  }
`;
export const LENGTH_PRODUK = gql`
  query listProduk {
    listProduk(
      listProdukInput: {
        page: 1
        limit: 9999
        search: { keyword: "" }
        filter: { isMatpro: null, isSample: null }
      }
    ) {
      total
    }
  }
`;
export const Q_LIST_CROP = gql`
  query allTanaman($page: Int, $limit: Int, $keyword: String) {
    listCropName(
      listCropNameInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
      }
    ) {
      page
      total
      totalPage
      crops {
        id
        crop
      }
    }
  }
`;

export const Q_LIST_KABUPATEN = gql`
  query listKab($keyword: String, $province_id: String!) {
    listKabupaten(
      listKabupatenInput: {
        page: 1
        limit: 99999
        search: { keyword: $keyword, province_id: $province_id }
      }
    ) {
      kabupatens {
        id
        kabupaten
      }
    }
  }
`;

export const Q_LIST_OUTSTANDING = gql`
  query listOutstanding(
    $page: Int
    $limit: Int
    $region: [Int!]
    $keyword: String
    $from: String
    $to: String
  ) {
    listOutstanding(
      listOutstandingInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword, from: $from, to: $to, region_id: $region }
      }
    ) {
      page
      limit
      total
      totalPage
      outstandings {
        id
        from
        froms {
          id
          name
        }
        to
        tos {
          name
        }
        file
        isSaw
        feedback
      }
    }
  }
`;

export const Q_LIST_COLLECTION = gql`
  query listCollection(
    $page: Int
    $limit: Int
    $keyword: String
    $region: [ID!]
    $rm: [ID!]
  ) {
    listCollection(
      listCollectionInput: {
        page: $page
        limit: $limit
        search: { keyword: $keyword, region_id: $region, rm_id: $rm }
      }
    ) {
      page
      limit
      total
      totalPage
      collections {
        id
        collection_id
        rm_id
        region_id
        regions {
          id
          region
        }
        distributors {
          id
          name
        }
        tanggal
        tagihan
        targets {
          id
        }
      }
    }
  }
`;
export const Q_LIST_TARGETCOL = gql`
  query listTargetCollection(
    $page: Int
    $limit: Int
    $keyword: String
    $region: [ID!]
    $rm: ID
    $status: Boolean
  ) {
    listTargetCollection(
      listTargetCollectionInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          region_id: $region
          rm_id: $rm
          isReported: $status
        }
      }
    ) {
      page
      limit
      total
      totalPage
      tagetcols {
        id
        regional_manager {
          id
          name
        }
        regions {
          id
          region
        }
        distributors {
          id
          name
        }
        target
        isReported
      }
    }
  }
`;

export const Q_LIST_REPORT = gql`
  query listLaporan(
    $page: Int
    $limit: Int
    $keyword: String
    $role: roleType
    $from: [String!]
    $to: String
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $r1: [Int!]
    $r2: [Int!]
    $dis: [Int!]
    $petani: [String!]
    $produk: [String!]
    $start: DateTime
    $end: DateTime
    $dateType: filterDateType
    $orderBy: LaporanOrderType!
    $sortBy: SortByType!
  ) {
    listLaporan(
      listLaporanInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          from: $from
          to: $to
          role: $role
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          kec_id: []
          kel_id: []
          r1: $r1
          r2: $r2
          dis: $dis
          petani_id: $petani
          produk_id: $produk
          start_date: $start
          end_date: $end
          dateType: $dateType
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      page
      total
      totalPage
      laporans {
        id
        day_report
        month
        year
        froms {
          id
          name
          role
          detail {
            regions {
              id
              region
            }
            province {
              id
              province
            }
          }
        }
        produknames {
          id
          brand
        }
        price
        quantity
        value
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        distributors {
          id
          name
        }
        invoice
      }
    }
  }
`;
export const Q_LIST_DAILYREPORT = gql`
  query listDailyReport(
    $page: Int
    $limit: Int
    $keyword: String
    $user_id: [ID!]
    $region_id: [ID!]
    $type: dailyActivityType
    $role_types: roleType
    $start_date: DateTime
    $end_date: DateTime
    $dateType: filterDateType
  ) {
    listDailyReport(
      listDailyReportInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          user_id: $user_id
          region_id: $region_id
          daily_types: $type
          role_types: $role_types
          start_date: $start_date
          end_date: $end_date
          dateType: $dateType
        }
        order: { sortBy: DESC, orderBy: TANGGAL }
      }
    ) {
      page
      limit
      total
      totalPage
      dailys {
        id
        user_id
        users {
          name
        }
        role
        region_id
        regions {
          region
        }
        tanggal
        kegiatan
        d1
        hd1
        distributor1 {
          id
          name
          owner
        }
        d2
        hd2
        distributor2 {
          id
          name
          owner
        }
        r1
        hr1
        retailer1 {
          id
          name
          owner
        }
        retailer2 {
          id
          name
          owner
        }
        retailer3 {
          id
          name
          owner
        }
        petani1 {
          id
          name
        }
        petani2 {
          id
          name
        }
        r2
        hr2
        r3
        hr3
        p1
        hp1
        p2
        hp2
        nama_kebun
        hasil_kebun
        nama_marketing
        hasil_marketing
        nama_administrasi
        hasil_administrasi
        dokumentasi
        createdAt
      }
    }
  }
`;

export const Q_LIST_REGION = gql`
  query listRegion {
    listRegion(
      listRegionInput: {
        page: 1
        limit: 999
        search: { keyword: "" }
        order: { orderBy: REGION, sortBy: ASC }
      }
    ) {
      regions {
        id
        region
      }
    }
  }
`;

// MATPRO TYPES
export const listMatproTypes = gql`
  query listMatproTypes(
    $page: Int
    $limit: Int
    $keyword: String
    $orderBy: JenisMatproOrderType!
    $sortBy: SortByType!
  ) {
    listJenisMaterialPromosi(
      input: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      limit
      page
      total
      totalPage
      jenismatpros {
        id
        matpro
        createdAt
      }
    }
  }
`;

// MATERIAL PROMOSI
export const listMatpros = gql`
  query listMatpros(
    $page: Int
    $limit: Int
    $keyword: String
    $jenisMatproId: String
    $produkId: String
    $orderBy: MatproOrderType!
    $sortBy: SortByType!
  ) {
    listMaterialPromosi(
      input: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        filter: { jenisMatproId: $jenisMatproId, produkId: $produkId }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      page
      limit
      total
      totalPage
      matpros {
        id
        jenisMatpros {
          id
          matpro
        }
        produks {
          id
          brand
        }
        stock
      }
    }
  }
`;

export const detailMatpro = gql`
  query detailMatpro($id: ID!) {
    detailMaterialPromosi(id: $id) {
      id
      jenis_matpro_id
      produk_id
      stock
    }
  }
`;

// PROPOSAL REQUEST
export const listProposalReq = gql`
  query listReqProposal(
    $page: Int
    $limit: Int
    $keyword: String
    $userId: String
    $periodeId: String
    $tipe: InventoryType
    $dayRequest: DateTime
    $status: statusProposalType
    $isGetFromMobile: Boolean
    $orderBy: RequestProposalOrderType!
    $sortBy: SortByType!
  ) {
    listRequestProposal(
      input: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        filter: {
          dayRequest: $dayRequest
          isGetFromMobile: $isGetFromMobile
          periodeId: $periodeId
          status: $status
          tipe: $tipe
          userId: $userId
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      limit
      page
      total
      totalPage
      requestProposals {
        id
        periods {
          id
          startDate
          endDate
        }
        status
        users {
          id
          name
          ugdn
        }
        createdAt
      }
    }
  }
`;

export const detailProposalReq = gql`
  query detailReqProposal($id: ID!) {
    detailRequestProposal(id: $id) {
      id
      jumlahSP
      tipe
      status
      approvedBy
      approvedBys {
        id
        name
      }
      periods {
        id
        startDate
        endDate
      }
      users {
        id
        name
      }
      proposals {
        id
        jumlahSample
        kebutuhanSample
        kebutuhanSampleApproved
        kebutuhanSampleApprovedGudang
        tujuanPromosi
        matpros {
          id
          jenisMatpros {
            id
            matpro
          }
          produks {
            id
            brand
          }
          stock
        }
        status
      }
    }
  }
`;

export const detailProposalReqSample = gql`
  query detailReqProposal($id: ID!) {
    detailRequestProposal(id: $id) {
      id
      jumlahSP
      tipe
      status
      approvedBy
      approvedBys {
        id
        name
      }
      periods {
        id
        startDate
        endDate
      }
      users {
        id
        name
      }
      proposals {
        id
        jumlahSample
        kebutuhanSample
        rencanaDemoplot
        kebutuhanSampleApproved
        kebutuhanSampleApprovedGudang
        produks {
          id
          brand
          jumlah_bungkus_botol
          volume_per_demoplot
          kebutuhan_sample
          jumlah_tangki
        }
        status
      }
    }
  }
`;

// ACCOUNT REQUEST
export const listAccountRequest = gql`
  query listReqAccount(
    $page: Int
    $limit: Int
    $keyword: String
    $tssId: String
    $tipe: RequestRoleType
    $tanggalMulaiBekerja: DateTime
    $status: FilterListRequestAccountType!
    $orderBy: RequestAccountOrderType!
    $sortBy: SortByType!
  ) {
    listRequestAccount(
      input: {
        page: $page
        limit: $limit
        search: { keyword: $keyword }
        filter: {
          tipe: $tipe
          tssId: $tssId
          status: $status
          tanggalMulaiBekerja: $tanggalMulaiBekerja
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      limit
      page
      total
      totalPage
      requestAccounts {
        id
        name
        email
        noKTP
        domisili
        password
        fotoKTP
        phone
        profile
        role
        status
        tanggalMulaiKerja
        users {
          id
          name
        }
      }
    }
  }
`;

export const detailAccountRequest = gql`
  query detailReqAccount($id: ID!) {
    detailRequestAccount(id: $id) {
      id
      email
      name
      domisili
      password
      role
      tanggalMulaiKerja
      noKTP
      fotoKTP
      phone
      profile
      status
      Requests {
        id
        group_id
        groups {
          id
          group
        }
        country_id
        countrys {
          id
          country
        }
        region_id
        regions {
          id
          region
        }
        province_id
        province {
          id
          province
        }
        kabupaten_id
        kabupaten {
          id
          kabupaten
        }
      }
      tssId
      users {
        id
        name
      }
    }
  }
`;

// INVENTORIES
export const listInventories = gql`
  query listInventories(
    $page: Int
    $limit: Int
    $keyword: String
    $userId: String
    $produkId: String
    $matproId: String
    $tipe: InventoryType
    $isGetFromMobile: Boolean
    $orderBy: InventoryOrderType!
    $sortBy: SortByType!
  ) {
    listInventory(
      input: {
        limit: $limit
        page: $page
        search: { keyword: $keyword }
        filter: {
          isGetFromMobile: $isGetFromMobile
          matproId: $matproId
          produkId: $produkId
          tipe: $tipe
          userId: $userId
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      limit
      page
      total
      totalPage
      inventorys {
        id
        matpros {
          id
          jenisMatpros {
            id
            matpro
          }
          produks {
            id
            brand
          }
        }
        produks {
          id
          brand
        }
        jumlah
        tipe
      }
    }
  }
`;

export const detailInventory = gql`
  query detailInventory($id: ID!) {
    detailInventory(id: $id) {
      id
      userId
      produkId
      matproId
      tipe
      jumlah
      createdAt
      users {
        id
        ugdn
        name
      }
      produks {
        id
        brand
        volume_per_demoplot
        jumlah_tangki
        kebutuhan_sample
        jumlah_bungkus_botol
      }
      matpros {
        id
        jenis_matpro_id
        produk_id
        stock
        createdAt
        jenisMatpros {
          id
          matpro
        }
      }
    }
  }
`;

export const listPeriods = gql`
  query listPeriode(
    $page: Int
    $limit: Int
    $orderBy: PeriodeOrderType!
    $sortBy: SortByType!
  ) {
    listPeriode(
      input: {
        page: $page
        limit: $limit
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      page
      limit
      total
      totalPage
      periods {
        id
        startDate
        endDate
        startYear
        endYear
      }
    }
  }
`;

export const listBreakdowns = gql`
  query listBreakdown(
    $page: Int
    $limit: Int
    $userId: String
    $tipe: InventoryType # $orderBy: BreakdownOrderType! # $sortBy: SortByType!
  ) {
    listBreakdown(
      input: {
        page: $page
        limit: $limit
        filter: { tipe: $tipe, userId: $userId }
        order: { orderBy: TANGGAL_BREAKDOWN, sortBy: DESC }
      }
    ) {
      page
      limit
      total
      totalPage
      breakdowns {
        id
        tipe
        tanggalBreakdown
        users {
          id
          name
        }
      }
    }
  }
`;

export const Q_LIST_KEGIATAN = gql`
  query listLaporanKegiatan(
    $page: Int
    $limit: Int
    $keyword: String
    $sp_id: [String!]
    $tss_id: [String!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $start_date: DateTime
    $end_date: DateTime
    $kegiatan: kegiatanSalesPromotorType
    $dateType: filterDateType
  ) {
    listLaporanKegiatan(
      listLaporanKegiatanInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          sp_id: $sp_id
          tss_id: $tss_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          start_date: $start_date
          end_date: $end_date
          kegiatan: $kegiatan
          dateType: $dateType
        }
        order: { orderBy: CREATED_AT, sortBy: DESC }
      }
    ) {
      page
      total
      totalPage
      lapkegs {
        id
        country_id
        region_id
        province_id
        kabupaten_id
        sp_id
        tss_id
        tanggal_kegiatan
        lokasi
        kegiatan
        petani_id
        tanaman_id
        hst
        opt_sasaran
        luas_demoplot
        tanggal_aplikasi
        tanggal_pengamatan
        volume
        kesimpulan
        dokumentasi
        jml_petani_undang
        jml_petani_hadir
        jml_petani_aplikator
        isExist
        kekurangan
        kelebihan
        level_toko
        retailer_id
        jml_hasil_penjualan
        jml_beli_langsung
        produk_liquidasi
        budget
        hadiah
        fokus_produk
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provinces {
          id
          province
        }
        kabupatens {
          id
          kabupaten
        }
        sales_promotor {
          id
          name
        }
        teritory_sales_supervisor {
          id
          name
        }
        petanis {
          id
          name
          alamat
          hp
          luas
          ucrops {
            id
            crop
          }
          ocrops {
            id
            crop
          }
        }
        tanamans {
          id
          crop
        }
        retailers {
          id
          retailer_type
          name
          alamat
          owner
          mobile
        }
        fokus_produks {
          id
          brand
        }
        aplikasiKegiatan {
          id
          produk {
            id
            brand
          }
          konsentrasi_rekomendasi
        }
        LiquidasiKegiatan {
          id
          jumlah_produk
          produk {
            id
            brand
          }
        }
        salesKegiatan {
          laporan_kegiatan_id
          tujuan_sales_call_petani
          tujuan_sales_call_kios
          stok
          support_kegiatan
          po_produk
          pembayaran
        }
      }
    }
  }
`;
