<template>
  <div class="custom-tooltip">
    <slot></slot>
    <span class="tooltiptext">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String
    }
  }
};
</script>

<style>
.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltiptext {
  opacity: 0;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
}

.custom-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
}

.custom-tooltip:hover .tooltiptext {
  opacity: 1;
  transition: 0.2s;
}
</style>
