import moment from "moment";
import { CLEAR } from "@/core/services/store/auth.module";
import jwtService from "@/core/services/jwt.service";
import Vue from "vue";

Vue.mixin({
  methods: {
    toastAlert(act, type = "success") {
      this.$swal({
        toast: true,
        position: "top-end",
        iconColor: "white",
        showClass: { popup: "" },
        customClass: {
          popup: "colored-toast"
        },
        icon: type,
        title: `${act}`,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        }
      });
    },
    errorAlert(data) {
      const errIndex = data.message.split(": ");
      const errData =
        errIndex.length === 2
          ? data.message.split(": ")[1]
          : errIndex.length === 3
          ? data.message.split(": ")[2]
          : "Error!";
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: errData,
        width: "26em",
        confirmButtonColor: "#fd8833"
      });
    },
    catchError: function(error) {
      const em = ["invalid token", "invalid signature", "token expired"];
      if (em.includes(error.message.split(": ")[1].toLowerCase())) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Session Expired!",
          width: 400,
          confirmButtonText: "OK",
          confirmButtonColor: "#fd8833"
        });
        this.$store.dispatch(CLEAR);
        this.$router.push("/login");
      } else if (
        error.message.split(": ")[1].toLowerCase() !== "throttlerexception"
      ) {
        if (error.message.split(": ")[1]) {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: error.message.split(": ")[1],
            width: 400,
            confirmButtonText: "OK",
            confirmButtonColor: "#fd8833"
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: error,
            width: 400,
            confirmButtonText: "OK",
            confirmButtonColor: "#fd8833"
          });
        }
      }
    },
    deleteConfirm() {
      return this.$swal.fire({
        title: this.$t("global.delete_confirm"),
        text: this.$t("global.delete_text"),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#fd8833",
        cancelButtonColor: "#c4c4c4",
        confirmButtonText: this.$t("global.delete_btn"),
        cancelButtonText: this.$t("global.cancel"),
        width: "26em"
      });
    },
    resetAlert() {
      return this.$swal
        .fire({
          icon: "warning",
          title: "Warning",
          text:
            "Demi keamanan akun anda, silahkan ganti password secara berkala",
          width: "26em",
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonColor: "#fd8833",
          cancelButtonColor: "#c4c4c4",
          cancelButtonText: "Skip",
          confirmButtonText: "Reset"
        })
        .then(result => {
          if (result.isConfirmed) {
            const route = this.$route.path.split("/")[1];
            this.$router.push(`/${route}/settings`);
          }
        });
    },
    dateFr: (date, format = "YYYY-MM-DD") => {
      if (date) {
        return moment(date).format(format);
      }
    },
    datePr: (date, format = "MMM YYYY") => {
      if (date) {
        return moment(date).format(format);
      }
    },
    dateTz: (date, tz, format = "DD/MM/YYYY") => {
      if (date) {
        return moment.tz(date, tz).format(format);
      } else {
        return "-";
      }
    },
    formatJson: (filterVal, jsonData) => {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    getCountryID() {
      const dt = JSON.parse(jwtService.getDetail());
      const res = [];
      dt.map(a => {
        if (a.country_id) res.push(parseInt(a.country_id));
      });
      return Array.from(new Set(res));
    },
    getRegionID() {
      const dt = JSON.parse(jwtService.getDetail());
      const res = [];
      dt.map(b => {
        if (b.region_id) res.push(parseInt(b.region_id));
      });
      return Array.from(new Set(res));
    },
    getProvinceID() {
      const dt = JSON.parse(jwtService.getDetail());
      const res = [];
      dt.map(c => {
        if (c.province_id) res.push(c.province_id);
      });
      return Array.from(new Set(res));
    },
    getKabupatenID() {
      const dt = JSON.parse(jwtService.getDetail());
      const res = [];
      dt.map(d => {
        if (d.kabupaten_id) res.push(d.kabupaten_id);
      });
      return Array.from(new Set(res));
    },
    convertKegDaily(data) {
      switch (data) {
        case "VD":
          return "visit_distributor";
        case "VR1":
          return "visit_retailer_1";
        case "VR2":
          return "visit_retailer_2";
        case "VF":
          return "visit_farmer";
        case "VK":
          return "visit_kebun";
        case "MA":
          return "marketing_activity";
        case "ADM":
          return "administration";
        default:
          return null;
      }
    },
    convertKegMP(data) {
      switch (data) {
        case "DP":
          return "demo_plot";
        case "FFD":
          return "ffd";
        case "SMF":
          return "small_fm";
        case "SAF":
          return "safari_fm";
        case "ODP":
          return "odp";
        case "BF":
          return "big_fm";
        case "KF":
          return "key_fm";
        case "SE":
          return "sponsorship_event";
        case "R1M":
          return "r1_meeting";
        case "R2M":
          return "r2_meeting";
        default:
          return null;
      }
    },
    convertKegSP(data) {
      switch (data) {
        case "DEMOPLOT":
          return "demoplot";
        case "SPRAY":
          return "spray_massal";
        case "BFM":
          return "big_farmer_meeting";
        case "FFD":
          return "ffd";
        case "ODP":
          return "odp";
        case "KFM":
          return "key_farmer_meeting";
        case "SCK":
          return "sales_call_kios";
        case "SCP":
          return "sales_call_petani";
        case "SFM":
          return "small_farmer_meeting";
        default:
          return null;
      }
    },
    handleCustomDateExport(data) {
      switch (data) {
        case "YESTERDAY":
          return "yesterday";
        case "TODAY":
          return "today";
        case "THIS_WEEK":
          return "this_week";
        case "THIS_MONTH":
          return "this_month";
        case "CUSTOM_ONE_DAY":
          return "custom_one_day";
        case "CUSTOM_DATE":
          return "custom_date";
        case "LAST_SEVEN_DAYS":
          return "last_seven_days";
        case "LAST_THIRTY_DAYS":
          return "last_thirty_days";
        case "LAST_NINETY_DAYS":
          return "last_ninety_days";
        default:
          return null;
      }
    },
    convertRoles(data) {
      switch (data) {
        case "CM":
          return "Country Head of Indonesia";
        case "ADVISOR":
          return "Advisor";
        case "NSM":
          return "National Sales Manager";
        case "NMM":
          return "Marketing Head of Indonesia";
        case "SA":
          return "Sales Admin";
        case "MT":
          return "Marketing Team";
        case "RM":
          return "Regional Manager";
        case "AM":
          return "Areal Manager";
        case "TSS":
          return "Territory Sales Supervisor";
        case "SP":
          return "Sales Promotor";
        case "SUPER_ADMIN":
          return "Super Admin";
        default:
          return "Wrong Role";
      }
    },
    convertValueMB(val) {
      return (val / (1024 * 1024)).toFixed(2);
    },
    convertTujuanPromosi(val) {
      switch (val) {
        case "SAFM":
          return "Support Activity FM";
        case "SAOD":
          return "Support Activity ODP";
        case "SAFF":
          return "Support Activity FFD";
        case "DB":
          return "Demoplot Branding";
        case "EB":
          return "Event Branding";
        case "KB":
          return "Kios Branding";
        case "PB":
          return "Produk Branding";
        default:
          break;
      }
    }
  }
});
