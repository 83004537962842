import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
// import * as VueGoogleMaps from "vue2-google-maps";
// import Multiselect from "vue-multiselect";
import JwtService from "@/core/services/jwt.service";
import { ApolloLink } from "apollo-link";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import moment from "moment";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "remixicon/fonts/remixicon.css";

import "./plugins/mixins";

// import VueGitHubButtons from "vue-github-buttons"
// import "vue-github-buttons/dist/vue-github-buttons.css"
import "@/assets/scss/white-dashboard.scss";
import "@/assets/css/nucleo-icons.css";
// import "@/assets/demo/demo.css";

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import RTLPlugin from "./RTLPlugin";
import Notify from "@/components/NotificationPlugin";
import i18n from "./i18n";
import SideBar from "@/components/SidebarPlugin";
import VueCompositionAPI from "@vue/composition-api";
import VueSweetalert2 from "vue-sweetalert2";

import "./plugins/bootstrap-vue";
// import "./assets/scss/style.scss";

import "sweetalert2/dist/sweetalert2.min.css";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// library.add(faEye, faEyeSlash, faArrowLeft)

// Vue.use(ToggleButton);
Vue.component("v-select", vSelect);
// Vue.use(SocialSharing);
Vue.use(VueSweetalert2);
Vue.use(VueCompositionAPI);
Vue.use(Vuelidate);
// Vue.use(VueGitHubButtons, { useCache: true });
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(RTLPlugin);
Vue.use(SideBar);
Vue.use(Notify);

Vue.prototype.$window = window;

Vue.filter("formatRp", function (value) {
  value = parseFloat(value);
  let rupiah = "";
  let valueRev = value
    .toString()
    .split("")
    .reverse()
    .join("");
  for (let i = 0; i < valueRev.length; i++) {
    if (i % 3 === 0) rupiah += `${valueRev.substr(i, 3)}.`;
  }
  const rp = rupiah
    .split("", rupiah.length - 1)
    .reverse()
    .join("");
  if (rupiah === "NaN." || rupiah === "NaN") {
    return "...";
  }
  return `Rp ${rp}`;
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("DD-MMM-YY HH:mm:ss");
  }
});

Vue.filter("formatDate2", function (value) {
  if (value) {
    return moment(value).format("DD\xa0MMM\xa0YYYY");
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = JwtService.getToken();
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : null
    }
  });

  return forward(operation);
});
const httpLink = new HttpLink({
  uri: `${process.env.VUE_APP_URL_GRAPHQL}/v1/query`
  // uri: `https://gql-telkominfra-uso.oemahsolution.com/v1/graphql`
});
// apollo client setup
export const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only"
    }
  }
});

// Install the vue plugin
Vue.use(VueApollo);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLEMAP_KEY,
//     libraries: "places"
//   }
// });

// Apollo provider init
const apolloProvider = new VueApollo({
  defaultClient: client
});

Vue.config.productionTip = false;

import { VERIFY_AUTH } from "@/core/services/store/auth.module";

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  i18n,
  store,
  //provide: apolloProvider.provide(),
  apolloProvider,
  render: h => h(App),
  validations: {}
}).$mount("#app");
