/* eslint-disable */
// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import gql from "graphql-tag";
import { client } from "../../../main";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGINVERIFY = "loginVerify";
export const LOGOUT = "logout";
export const CLEAR = "clear";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

export const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
  // isAdmin: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  // isAdmin(state) {
  //   return state.isAdmin;
  // },
  errorData(state) {
    return state.errors
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};
const actions = {
  [LOGIN](context, credentials) {
    return new Promise(async (resolve, reject) => {
      try {
        // const login = await this.login(email, password);
        const login = await client.mutate({
          // Query
          mutation: gql`
            mutation ($email: String, $ugdn: String, $password: String!){
              login(loginInput:{
                email: $email,
                ugdn: $ugdn,
                password: $password,
                device: "Web"
              }){
                id, ugdn, email, token, phone, name, role, isActive, profile, urlDomainFile, mustReset
                detail{
                  country_id
                  region_id
                  province_id
                  kabupaten_id
                }
              }
            }
          `,
          // Parameters
          variables: {
            email: credentials.email,
            ugdn: credentials.ugdn,
            password: credentials.password,
          }
        });
        const data = login.data.login;
        if (data) {
          context.commit(SET_AUTH, data);
          resolve(data);
        }
      } catch (e) {
        context.commit(SET_ERROR, e.message.split(": ")[2]);
        reject(e);
      }
    });
  },
  [LOGOUT](context, token) {
    return new Promise((resolve, reject) => {
      const logout = client.mutate({
        mutation: gql`
        mutation ($token: String!){
          logoutUser(
            token: $token
          )
        }
      `,
        variables: {
          token: token
        }
      })
      if (resolve) {
        resolve(logout);
      } else {
        reject(logout);
      }
    }).then((res) => {
      if (res) {
        context.commit(PURGE_AUTH);
      }
    })
  },
  [CLEAR](context) {
    context.commit(PURGE_AUTH);
  },
  // [REGISTER](context, credentials) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post("register", { user: credentials })
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //         reject(response);
  //       });
  //   });
  // },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken() === null || JwtService.getToken() === undefined || JwtService.getToken() === "") {
      context.commit(PURGE_AUTH);
    }
    // JwtService
    // if (JwtService.getToken()) {
    //   // console.log("OKE");
    //   client
    //     .query({
    //       // Query
    //       query: gql`
    //         query($id: ID!){
    //           refreshToken(
    //             device: "Web"
    //             id: $id
    //           ){
    //             token
    //           }
    //         }
    //       `,
    //       variables:{
    //         id: id
    //       }
    //       // query: gql`
    //       //   query($id: String!) {
    //       //     verify(detailUserInput: { id: $id }) {
    //       //       id
    //       //       isActive
    //       //       fullName
    //       //       role
    //       //     }
    //       //   }
    //       // `,
    //       // variables: {
    //       //   id: (Math.random() + 1).toString(36).substring(7)
    //       // }
    //     })
    //     .then(() => {
    //       // if (!data.verify.isActive) {
    //         // console.log("isactive", data);
    //         // context.commit(PURGE_AUTH);
    //       // }
    //       // saveRole(data.verify.role);
    //       console.log("OKE");
    //     })
    //     .catch(e => {
    //       console.log("ERR" + e);
    //       // context.commit(PURGE_AUTH);
    //       if (
    //         e.message === "GraphQL error: Token Expired" ||
    //         e === "GraphQL error: Token Expired"
    //       ) {
    //         context.commit(SET_ERROR, e);
    //         context.commit(PURGE_AUTH);
    //       }
    //     });
    // } else {
    //   context.commit(PURGE_AUTH);
    // }
  },
  // [UPDATE_USER](context, payload) {
  //   const { email, username, password, image, bio } = payload;
  //   const user = { email, username, bio, image };
  //   if (password) {
  //     user.password = password;
  //   }

  //   return ApiService.put("user", user).then(({ data }) => {
  //     context.commit(SET_AUTH, data);
  //     return data;
  //   });
  // }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
    JwtService.saveName(state.user.name);
    JwtService.saveRole(state.user.role);
    JwtService.saveEmail(state.user.email);
    JwtService.saveID(state.user.id);
    JwtService.saveUGDN(state.user.ugdn);
    JwtService.saveDetail(state.user.detail);
    JwtService.savePhone(state.user.phone);
    JwtService.savePP(state.user.profile);
    JwtService.saveURL(state.user.urlDomainFile);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyName();
    JwtService.destroyRole();
    JwtService.destroyEmail();
    JwtService.destroyID();
    JwtService.destroyUGDN();
    JwtService.destroyPhone();
    JwtService.destroyPP();
    JwtService.destroyURL();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
