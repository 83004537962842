<template>
  <nav class="navbar navbar-expand-lg navbar-absolute bg-white pr-0">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button type="button" class="navbar-toggler" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#">
          <img
            src="@/assets/img/upltext.jpg"
            class="img-responsive mx-auto d-block"
          />
        </a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>
      <div class="collapse navbar-collapse show text-left" v-show="showMenu">
        <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
          <!-- <li class="search-bar input-group" @click="searchModalVisible = true">
            <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal"><i
                class="tim-icons icon-zoom-split"></i>
              <span class="d-lg-none d-md-block">Search</span>
            </button>
          </li> -->
          <!-- <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false"
            :show-close="true">
            <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup"
              placeholder="SEARCH">
          </modal> -->
          <!-- <drop-down>
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              class="dropdown-toggle nav-link"
            >
              <div class="notification d-none d-lg-block d-xl-block"></div>
              <img
                src="@/assets/img/notif.png"
                class="img-responsive mx-auto d-block"
              />
              <i class="tim-icons icon-sound-wave"></i>
              <p class="d-lg-none text-left">
                Notifications
              </p>
            </a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-navbar">
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Notifications</a>
              </li>
              <li class="nav-link">
                <a href="javascript:void(0)" class="nav-item dropdown-item"
                  >You have 5 more tasks</a
                >
              </li>
              <li class="nav-link">
                <a href="javascript:void(0)" class="nav-item dropdown-item"
                  >Your friend Michael is in town</a
                >
              </li>
              <li class="nav-link">
                <a href="javascript:void(0)" class="nav-item dropdown-item"
                  >Another notification</a
                >
              </li>
              <li class="nav-link">
                <a href="javascript:void(0)" class="nav-item dropdown-item"
                  >Another one</a
                >
              </li>
            </ul>
          </drop-down> -->
          <!-- <drop-down>
          </drop-down> -->
          <drop-down v-if="role !== 'SUPER_ADMIN'" class="mr-3">
            <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
              <div class="row language-section d-flex align-items-center">
                <div class="photo-sq" style="padding-top: 2px;">
                  <img
                    :src="
                      require(`@/assets/img/locales/${$i18n.locale.toUpperCase()}.png`)
                    "
                    alt="locales"
                    style="width: 1.8em; margin-top: 2px; border-radius: 3px;"
                  />
                </div>
                <span class="language-id">
                  {{ $i18n.locale.toUpperCase() }}
                </span>
                <b class="caret caret-lang d-none d-lg-block d-xl-block"></b>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-navbar">
              <li class="nav-link">
                <a
                  href="javascript:void(0)"
                  class="nav-item dropdown-item dropdown-lang"
                  v-for="lang in $i18n.availableLocales"
                  :key="lang.id"
                  @click="applyLocales(lang)"
                >
                  <div class="photo-sq">
                    <img
                      :src="
                        require(`@/assets/img/locales/${lang.toUpperCase()}.png`)
                      "
                      alt="locales"
                      style="width: 1.8em; margin-top: 2px; border-radius: 3px; margin-top: 6px;"
                    />
                  </div>
                  <span>{{ regionNames(lang) }}</span>
                </a>
              </li>
            </ul>
          </drop-down>
          <a
            href="javascript:void(0)"
            data-toggle="dropdown"
            class="nav-link role-navbar mt-2"
          >
            <p>{{ $t("global.greetings") + name }}</p>
          </a>
          <drop-down>
            <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
              <div class="photo">
                <img src="@/assets/img/upl-square.png" alt="Profile Photo" />
              </div>
              <!-- <p class="d-lg-none" @click="onLogout">
                Log out
              </p> -->
            </a>
            <ul class="dropdown-menu dropdown-navbar">
              <!-- <li class="nav-link">
                <a href="javascript:void(0)" class="nav-item dropdown-item"
                  >Profile</a
                >
              </li> -->
              <li class="nav-link">
                <router-link
                  v-if="role === 'super_admin'"
                  :to="`/admin/settings`"
                  href="javascript:void(0)"
                  class="nav-item dropdown-item"
                >
                  {{ $t("global.profile_settings") }}
                </router-link>
                <router-link
                  v-else
                  :to="`/${role}/settings`"
                  href="javascript:void(0)"
                  class="nav-item dropdown-item"
                >
                  {{ $t("global.profile_settings") }}
                </router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li class="nav-link">
                <a
                  href="javascript:void(0)"
                  @click="onLogout"
                  class="nav-item dropdown-item"
                >
                  {{ $t("global.logout") }}
                </a>
              </li>
            </ul>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import DropDown from "@/components/Dropdown.vue";
import JwtService from "@/core/services/jwt.service";

// import Modal from "@/components/Modal.vue";
// import {
//   SidebarPlugin
// } from "@/components/index";

export default {
  components: {
    DropDown
    // Modal,
    // SidebarPlugin
  },
  data() {
    return {
      searchModalVisible: false,
      searchQuery: "",
      showMenu: false,
      role: JwtService.getRole().toLowerCase(),
      name: JwtService.getName(),
      token: JwtService.getToken(),
      detail: JwtService.getDetail()
    };
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT, this.token)
        .then(() => this.$router.push({ name: "login" }))
        .catch(err => {
          this.catchErr(err);
        });
    },
    applyLocales(locale) {
      if (locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
      }
    },
    regionNames(lang) {
      return new Intl.DisplayNames([lang], {
        type: "language"
      }).of(lang);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  mounted() {}
};
</script>
<style>
.navbar-brand {
  background-color: #ffffff !important;
  position: relative;
  left: 0;
  padding-left: 27px;
  padding-right: 75px;
  margin-top: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 15px !important;
  border-radius: 8px;
}

.role-navbar {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.img-responsive {
  width: 140px;
}

.language-section {
  background-color: #f1f1f1;
  padding: 0.3em 0.65em;
  border-radius: 6px;
  width: 6em;
}

.photo-sq {
  display: inline-block;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.dropdown-item.dropdown-lang {
  padding: 0 1em;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.language-id {
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 399px) {
  .navbar-brand {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
