<template>
  <div>
    <section v-if="role == 'SUPER_ADMIN'">
      <router-link :to="{ name: 'admin-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/dashboard.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'admin-likuidasi' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('likuidasi') }"
          @click="setActive('likuidasi')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.liq_report") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-dailyreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('daily_report') }"
          @click="setActive('daily_report')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.daily_report") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-monthlyplan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('monthly_plan') }"
          @click="setActive('monthly_plan')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.monthly_plan") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('target') }"
          @click="setActive('target')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target1") }}</p>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'admin-produk' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dataproduk') }"
          @click="setActive('dataproduk')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/product.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.product") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-kategori' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datakategori') }"
          @click="setActive('datakategori')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/book.png" />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.pesticide") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-user' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datausers') }"
          @click="setActive('datausers')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/users.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.user") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-distributor' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datadistributor') }"
          @click="setActive('datadistributor')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/users-folder.png"
            />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.distributor") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-retailer' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dataretailer') }"
          @click="setActive('dataretailer')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/users-folder.png"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.retailer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.farmer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-tanaman' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datatanaman') }"
          @click="setActive('datatanaman')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list2.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.plant") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-mptypes' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('matprotypes') }"
          @click="setActive('matprotypes')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list2.png" />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.matpro_type") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-matpro' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('matpro') }"
          @click="setActive('matpro')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list2.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.matpro") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-groups' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datagroup') }"
          @click="setActive('datagroup')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/region.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.group") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-periods' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dataperiode') }"
          @click="setActive('dataperiode')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/region.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.period") }}</p>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.terr_master") }}
      </div>

      <router-link :to="{ name: 'admin-country' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datacountry') }"
          @click="setActive('datacountry')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/country.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.country") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-region' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dataregion') }"
          @click="setActive('dataregion')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/region.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.region") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-province' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dataprovinsi') }"
          @click="setActive('dataprovinsi')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/region.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.province") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-kabupaten' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datakabupaten') }"
          @click="setActive('datakabupaten')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/region.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.regency") }}</p>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.user_mng") }}
      </div>

      <router-link :to="{ name: 'admin-tssapv' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('tssapv') }"
          @click="setActive('tssapv')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/users-folder.png"
            />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.tss_request") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'admin-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'CM' || role === 'ADVISOR'">
      <router-link :to="{ name: 'cm-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/dashboard.png"
              alt="dashboardNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'cm-sales-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanpenjualan') }"
          @click="setActive('laporanpenjualan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">
              {{ $t("sidebar.liq_report") }}
            </p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-daily-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanharian') }"
          @click="setActive('laporanharian')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-monthly-plan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('rencanabulanan') }"
          @click="setActive('rencanabulanan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('target') }"
          @click="setActive('target')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target1") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-laporankegiatan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lapkegiatansp') }"
          @click="setActive('lapkegiatansp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp_activity") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'cm-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.farmer") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-sp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">
            {{ $t("sidebar.sp") }}
          </p>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'NSM'">
      <router-link :to="{ name: 'nsm-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/dashboard.png"
              alt="dashboardNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nsm-set-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasettargetnsm') }"
          @click="setActive('datasettargetnsm')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/book.png"
              alt="book"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target") }}</p>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'nsm-sales-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lappenjualan') }"
          @click="setActive('lappenjualan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nsm-daily-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanharian') }"
          @click="setActive('laporanharian')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list4.png"
              alt="dailyReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nsm-monthly-plan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('rencanabulanan') }"
          @click="setActive('rencanabulanan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nsm-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('target') }"
          @click="setActive('target')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target1") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'cm-laporankegiatan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lapkegiatansp') }"
          @click="setActive('lapkegiatansp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp_activity") }}</p>
          </template>
        </div>
      </router-link>

      <!-- <div class="sidebar-group-title my-3">TARGETING</div> -->

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'nsm-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.farmer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'nsm-sp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.sp") }}</p>
        </div>
      </router-link>

      <!-- <router-link :to="{ name: 'nsm-set-collection' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('setcollection') }"
          @click="setActive('setcollection')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/book.png"
              alt="book"
            />
          </i>
          <p class="sidebar-text text-disabled">Data Set Collection</p>
        </div>
      </router-link> -->

      <router-link :to="{ name: 'nsm-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'NMM'">
      <router-link :to="{ name: 'nmm-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/dashboard.png"
              alt="dashboardNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'nmm-sales-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanpenjualan') }"
          @click="setActive('laporanpenjualan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-daily-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanharian') }"
          @click="setActive('laporanharian')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-monthly-plan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('rencanabulanan') }"
          @click="setActive('rencanabulanan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('target') }"
          @click="setActive('target')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target1") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-laporankegiatan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lapkegiatansp') }"
          @click="setActive('lapkegiatansp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp_activity") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'nmm-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.farmer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-sp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.sp") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'nmm-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'MT'">
      <router-link :to="{ name: 'mt-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/dashboard.png"
              alt="dashboardNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'mt-sales-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanpenjualan') }"
          @click="setActive('laporanpenjualan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-daily-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanharian') }"
          @click="setActive('laporanharian')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-monthly-plan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('rencanabulanan') }"
          @click="setActive('rencanabulanan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-target' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('target') }"
          @click="setActive('target')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.target1") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-laporankegiatan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lapkegiatansp') }"
          @click="setActive('lapkegiatansp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp_activity") }}</p>
          </template>
        </div>
      </router-link>

      <section v-if="userAuthorization">
        <div class="sidebar-group-title my-3">
          {{ $t("sidebar.divider.proposal") }}
        </div>

        <router-link
          :to="{ name: 'mt-matpro' }"
          v-if="userAuthorization.canApprove"
          class="nav-item"
        >
          <div
            :class="{ 'side-item-active': isActive('req-matpro') }"
            @click="setActive('req-matpro')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">
                {{ $t("sidebar.mpr") }}
              </p>
            </template>
          </div>
        </router-link>

        <router-link
          v-if="userAuthorization.canProcess"
          :to="{ name: 'mt-appmatpro' }"
          class="nav-item"
        >
          <div
            :class="{ 'side-item-active': isActive('approved-matpro') }"
            @click="setActive('approved-matpro')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">{{ $t("sidebar.mpa") }}</p>
            </template>
          </div>
        </router-link>

        <router-link
          v-if="userAuthorization.canDeliver"
          :to="{ name: 'mt-prcmatpro' }"
          class="nav-item"
        >
          <div
            :class="{ 'side-item-active': isActive('processed-matpro') }"
            @click="setActive('processed-matpro')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">{{ $t("sidebar.mpd") }}</p>
            </template>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'mt-sample' }"
          class="nav-item"
          v-if="userAuthorization.canApprove"
        >
          <div
            :class="{ 'side-item-active': isActive('req-sample') }"
            @click="setActive('req-sample')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">{{ $t("sidebar.spr") }}</p>
            </template>
          </div>
        </router-link>

        <router-link
          v-if="userAuthorization.canProcess"
          :to="{ name: 'mt-appsample' }"
          class="nav-item"
        >
          <div
            :class="{ 'side-item-active': isActive('approved-sample') }"
            @click="setActive('approved-sample')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">{{ $t("sidebar.spa") }}</p>
            </template>
          </div>
        </router-link>

        <router-link
          v-if="userAuthorization.canDeliver"
          :to="{ name: 'mt-prcsample' }"
          class="nav-item"
        >
          <div
            :class="{ 'side-item-active': isActive('processed-sample') }"
            @click="setActive('processed-sample')"
            class="nav-link side-item"
          >
            <i>
              <img
                class="icon"
                src="@/assets/img/sidebar-icon/list3.png"
                alt="salesReportNSM"
              />
            </i>
            <template v-if="!isRTL">
              <p class="sidebar-text">{{ $t("sidebar.spd") }}</p>
            </template>
          </div>
        </router-link>
      </section>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'mt-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.farmer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-sp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.sp") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'mt-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'SA'">
      <router-link :to="{ name: 'sa-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/dashboard.png"
              alt="dashboardNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'sa-sales-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanpenjualan') }"
          @click="setActive('laporanpenjualan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'sa-daily-report' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('laporanharian') }"
          @click="setActive('laporanharian')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'sa-monthly-plan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('rencanabulanan') }"
          @click="setActive('rencanabulanan')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'sa-laporankegiatan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('lapkegiatansp') }"
          @click="setActive('lapkegiatansp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list3.png"
              alt="salesReportNSM"
            />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp_activity") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'sa-petani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.farmer") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'sa-sp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img
              class="icon"
              src="@/assets/img/sidebar-icon/list1.png"
              alt="list1"
            />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.sp") }}</p>
        </div>
      </router-link>

      <router-link :to="{ name: 'sa-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <p class="sidebar-text text-disabled">{{ $t("sidebar.setting") }}</p>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'RM'">
      <router-link :to="{ name: 'rm-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/dashboard.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-settarget' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settarget') }"
          @click="setActive('settarget')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/book.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.target") }}</p>
          </template>
        </div>
      </router-link>
      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.reporting") }}
      </div>
      <router-link :to="{ name: 'rm-salesreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('salesreport') }"
          @click="setActive('salesreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list-edge.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-dailyreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dailyreport') }"
          @click="setActive('dailyreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list2.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'rm-amreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('amreport') }"
          @click="setActive('amreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.amrep") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-tssreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('tssreport') }"
          @click="setActive('tssreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.tssrep") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-spreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('spreport') }"
          @click="setActive('spreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sprep") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'rm-datasp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-datapetani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.farmer") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'rm-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.setting") }}</p>
          </template>
        </div>
      </router-link>
    </section>

    <section v-if="role === 'AM'">
      <router-link :to="{ name: 'am-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/dashboard.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'am-settarget' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settarget') }"
          @click="setActive('settarget')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/book.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.target") }}</p>
          </template>
        </div>
      </router-link>
      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.reporting") }}
      </div>
      <router-link :to="{ name: 'am-salesreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('salesreport') }"
          @click="setActive('salesreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list-edge.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'am-dailyreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dailyreport') }"
          @click="setActive('dailyreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list2.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>
      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>
      <router-link :to="{ name: 'am-tssreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('tssreport') }"
          @click="setActive('tssreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.tssrep") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'am-spreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('spreport') }"
          @click="setActive('spreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sprep") }}</p>
          </template>
        </div>
      </router-link>
      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>
      <router-link :to="{ name: 'am-datasp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'am-datapetani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.farmer") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'am-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.setting") }}</p>
          </template>
        </div>
      </router-link>
    </section>

    <section v-if="role == 'TSS'">
      <router-link :to="{ name: 'tss-dashboard' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dashboard') }"
          @click="setActive('dashboard')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/dashboard.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.dashboard") }}</p>
          </template>
        </div>
      </router-link>

      <router-link :to="{ name: 'tss-settarget' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settarget') }"
          @click="setActive('settarget')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/book.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.target") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.reporting") }}
      </div>

      <router-link :to="{ name: 'tss-salesreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('salesreport') }"
          @click="setActive('salesreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.liq_report") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-dailyreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('dailyreport') }"
          @click="setActive('dailyreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list4.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.daily_report") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-monthlyplan' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('monthlyplan') }"
          @click="setActive('monthlyplan')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/country.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.monthly_plan") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.report") }}
      </div>

      <router-link :to="{ name: 'tss-spreport' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('spreport') }"
          @click="setActive('spreport')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list3.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">
              {{ $t("sidebar.sprep") }}
            </p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.proposal") }}
      </div>

      <router-link :to="{ name: 'tss-invp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('modul-sampel') }"
          @click="setActive('modul-sampel')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template>
            <p class="sidebar-text">{{ $t("sidebar.sample_module") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-invs' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('modul-matpro') }"
          @click="setActive('modul-matpro')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template>
            <p class="sidebar-text">{{ $t("sidebar.matpro_module") }}</p>
          </template>
        </div>
      </router-link>

      <div class="sidebar-group-title my-3">
        {{ $t("sidebar.divider.data_master") }}
      </div>

      <router-link :to="{ name: 'tss-spapproval' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('spapproval') }"
          @click="setActive('spapproval')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list1.png" />
          </i>
          <template>
            <p class="sidebar-text">
              {{ $t("sidebar.sp_request") }}
            </p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-datasp' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datasp') }"
          @click="setActive('datasp')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list-edge.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.sp") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-datapetani' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('datapetani') }"
          @click="setActive('datapetani')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/list-edge.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.farmer") }}</p>
          </template>
        </div>
      </router-link>
      <router-link :to="{ name: 'tss-settings' }" class="nav-item">
        <div
          :class="{ 'side-item-active': isActive('settings') }"
          @click="setActive('settings')"
          class="nav-link side-item"
        >
          <i>
            <img class="icon" src="@/assets/img/sidebar-icon/settings.png" />
          </i>
          <template v-if="!isRTL">
            <p class="sidebar-text">{{ $t("sidebar.setting") }}</p>
          </template>
        </div>
      </router-link>
    </section>
  </div>
</template>
<script>
import jwtService from "@/core/services/jwt.service";
import { mapGetters } from "vuex";
import { detailAuthorization } from "@/graphql/queries";

export default {
  name: "sidebar-link",
  data() {
    return {
      activeItem: "dashboard",
      role: jwtService.getRole(),
      userAuthorization: {}
    };
  },
  inject: {
    autoClose: {
      default: true
    }
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: ""
        };
      }
    },
    tag: {
      type: String,
      default: "router-link"
    }
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    ...mapGetters(["isAuthenticated", "errorData", "currentUser"])
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
    }
  },
  apollo: {
    detailUser: {
      query: () => detailAuthorization,
      variables() {
        return {
          id: jwtService.getID()
        };
      },
      result({ data }) {
        this.userAuthorization = data.detailUser.approvePropos;
      }
    }
  },
  mounted() {
    this.activeItem = this.$route.path.split("/")[2];
  }
};
</script>

<style>
.sidebar-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.text-disabled {
  color: #757575;
}

.icon-disabled {
  color: #757575 !important;
}

.side-item {
  border-radius: 4px;
  margin-left: 8%;
  margin-right: 2%;
  padding-left: 2% !important;
  border-left: 4px solid #ffffff;
}

img.icon {
  margin-bottom: 5px;
  border-radius: 0%;
}

.side-item-active {
  background: rgba(253, 136, 51, 0.2);
  border-left: 4px solid #fd8833;
}

.sidebar-group-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
  margin-left: 14%;
  text-transform: uppercase;
}
</style>
