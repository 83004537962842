const state = {
  modalBreakdown: false,
  modalRequest: false,
  modalTitle: null
};

const getters = {
  modalTitle: state => {
    return state.modalTitle;
  },
  modalBreakdown: state => {
    return state.modalBreakdown;
  },
  modalRequest: state => {
    return state.modalRequest;
  }
};

const mutations = {
  setTitle: (state, title) => {
    state.modalTitle = title;
  },
  changeModalBreakdown: (state, status) => {
    state.modalBreakdown = status;
  },
  changeModalRequest: (state, status) => {
    state.modalRequest = status;
  }
};

export default {
  state,
  getters,
  mutations
};
