const ID_TOKEN_KEY = "id_token";
const ROLE = "role";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const getID = () => {
  return window.localStorage.getItem("idUser");
};

export const saveID = id => {
  window.localStorage.setItem("idUser", id);
};

export const destroyID = () => {
  window.localStorage.removeItem("idUser");
};

export const getName = () => {
  return window.localStorage.getItem("name");
};

export const saveName = name => {
  window.localStorage.setItem("name", name);
};

export const getEmail = () => {
  return window.localStorage.getItem("email");
};

export const saveEmail = email => {
  window.localStorage.setItem("email", email);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyName = () => {
  window.localStorage.removeItem("name");
};

export const destroyEmail = () => {
  window.localStorage.removeItem("email");
};

export const saveRole = role => {
  window.localStorage.setItem(ROLE, role);
};

export const getRole = () => {
  return window.localStorage.getItem(ROLE);
};

export const destroyRole = () => {
  window.localStorage.removeItem(ROLE);
};

export const saveDetail = detail => {
  window.localStorage.setItem("detail", JSON.stringify(detail));
};

export const getDetail = () => {
  return window.localStorage.getItem("detail");
};

export const destroyDetail = () => {
  window.localStorage.removeItem("detail");
};

export const saveUGDN = ugdn => {
  window.localStorage.setItem("ugdn", ugdn);
};

export const getUGDN = () => {
  return window.localStorage.getItem("ugdn");
};

export const destroyUGDN = () => {
  window.localStorage.removeItem("ugdn");
};

export const savePP = pp => {
  window.localStorage.setItem("pp", pp);
};
export const getPP = () => {
  return window.localStorage.getItem("pp");
};
export const destroyPP = () => {
  window.localStorage.removeItem("pp");
};

export const savePhone = phone => {
  window.localStorage.setItem("phone", phone);
};
export const getPhone = () => {
  return window.localStorage.getItem("phone");
};
export const destroyPhone = () => {
  window.localStorage.removeItem("phone");
};

export const saveURL = phone => {
  window.localStorage.setItem("URL", phone);
};
export const getURL = () => {
  return window.localStorage.getItem("URL");
};
export const destroyURL = () => {
  window.localStorage.removeItem("URL");
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveID,
  getID,
  destroyID,
  getName,
  saveName,
  destroyName,
  getEmail,
  saveEmail,
  destroyEmail,
  getRole,
  saveRole,
  destroyRole,
  saveDetail,
  getDetail,
  destroyDetail,
  saveUGDN,
  getUGDN,
  destroyUGDN,
  savePhone,
  getPhone,
  destroyPhone,
  savePP,
  getPP,
  destroyPP,
  saveURL,
  getURL,
  destroyURL
};
